import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import image from "../../images/main-banner-image.jpg";
import Button from "../ui/Button";
import { useAuth } from "../../context/AuthContext";

export default function JoinCTA2() {
	const {authModal} = useAuth();
	return (
		<div className="relative bg-gray-900">
			<div className="relative max-w-6xl mx-auto px-4 py-24 sm:px-6 md:px-8 md:py-26">
				<div className="md:mr-auto md:w-1/2 md:pr-10">
					<h2 className="text-3xl font-extrabold text-white sm:text-4xl font-hn-ex">
						{/* <span className="block">Ready to dive in?</span> */}
						<span className="block">Unlock your <br/>K-experience for free.</span>
					</h2>
					
					<div className="mt-8">
						<Button label="Get Started" onClick={() => authModal("join")} />
					</div>
				</div>
			</div>

			<div className="h-56 bg-primary-600 sm:h-72 md:absolute md:right-0 top-0 md:h-full md:w-1/2">
				<img className="w-full h-full object-cover" src={image} alt="" />
			</div>
		</div>
	);
}
