import React from "react"
import JoinCTA2 from "../components/cta/JoinCTA2"
import DownloadBanner from "../components/download/DownloadBanner"

import Layout from "../components/shared/layout"
import Seo from "../components/shared/seo"

const DownloadPage = () => (
  <Layout transparentNavbar={false}>
    <Seo title="Download" />
    <DownloadBanner />
    <JoinCTA2 />
  </Layout>
)

export default DownloadPage
