import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import mainImage from "../../images/main-banner-image.jpg";
import LogoImage from "../../images/dlogo.svg";
import AppStoreIcon from "../../images/appstore.svg";
import PlayStoreIcon from "../../images/playstore.svg";

export default function DownloadBanner() {
	return (
		<div className="bg-black">
			<div className="relative overflow-hidden">
				<div className="relative pt-6 pb-16 sm:pb-24">
				

					<div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-[11rem] sm:px-6">
						<div className="text-center">
							<h1 className="text-4xl tracking-tight font-extrabold text-neutral-900 sm:text-5xl md:text-6xl">
								<span className="block text-white">
									Bring Kpop / K-culture class
								</span>
								<span className="block text-yellow-500">into your room</span>
							</h1>
							<p className="mt-3 max-w-md mx-auto text-base text-neutral-400 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
								Bringing together some of the sharpest minds, creative artists,
								and influencers in Korean culture, music.
							</p>

							<div className="bg-black">
								<div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
									<h2 className="text-center text-neutral-400 text-sm font-semibold uppercase tracking-wide">
										The Director is available on Apple App Store and Google
										Play.
									</h2>
									<div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-4 lg:grid-cols-4">
										<div className="hidden md:flex col-span-1 flex justify-center md:col-span-2 lg:col-span-1"></div>
										<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
											<a href="https://apps.apple.com/xk/app/the-director-k-classes/id1605643132">
												<img
													className="w-full rounded-xl border-2 border-white "
													src={AppStoreIcon}
													alt="Mirage"
												/>
											</a>
										</div>
										<div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
											<a href="https://play.google.com/store/apps/details?id=com.mevox.thedirector">
												<img
													className="w-full rounded-xl border-2 border-white"
													src={PlayStoreIcon}
													alt="StaticKit"
												/>
											</a>
										</div>
										<div className="hidden md:flex col-span-1 flex justify-center md:col-span-3 lg:col-span-1"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="relative">
					<div className="absolute inset-0 flex flex-col" aria-hidden="true">
						<div className="flex-1" />
						<div className="flex-1 w-full bg-black" />
					</div>
					<div className="max-w-7xl mx-auto px-4 sm:px-6">
						<img
							className="relative rounded-lg shadow-lg"
							src={mainImage}
							alt="App screenshot"
						/>
					</div>
				</div> */}
			</div>
		</div>
	);
}
